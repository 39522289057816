import logo from './logo.svg';
import hearingLogo from './hearingLogo.png';
import leaflet from './leaflet.jpeg';
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={hearingLogo} className="hearingLogo" alt="hearingLogo" />*/}
        <div className="links"><a>Home</a> | <a>About us</a> | <a>Tinnitus</a> | <a>Products</a> | <a>Contact us</a> | <a>Testimonials</a></div>
        {/*<div>Email: TheHearingGroup@hotmail.com <div>Tel: 07742040930</div></div>*/}
  
        <img src={leaflet} className="leaflet" alt="hearingLogo" />
        
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
      </header>
    </div>
  );
}

export default App;
